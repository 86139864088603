<template>
    <div class="leochApply">
        <div class="t-center">理事申请</div>
        <div style="margin:50px 0px;">
            <el-steps :active="active" align-center>
                <el-step title="基本信息填写"></el-step>
                <el-step title="报名信息填写"></el-step>
                <el-step title="提交申请"></el-step>
                <el-step title="支付会费"></el-step>
            </el-steps>
        </div>
        <One v-if="active==0" @change="numChange" :forms="form"/>
        <Two v-else-if="active==1" @change="numChange" :forms="form"/>
        <Three v-else @change="numChange" :id="$route.query.id"/>
    </div>
</template>

<script>
import {committee_info} from '@/api/member'
import One from './one.vue'
import Two from './two.vue'
import Three from './three.vue'
export default {
    components:{One,Two,Three},
    data() {
        return {
            active:0,
            form:{},
        };
    },
    created() {
        if(this.$route.query.id){
            this.getInfo()
        }
    },
    mounted() {

    },
    methods: {
        getInfo(){
            committee_info(this.$route.query.id).then(res=>{
                if(res.code==200){
                    this.form = res.data
                }
            })
        },
        numChange(val){
            if(val==1){
                if(this.active<3){
                    this.active++
                }
            }else{
                if(this.active>0){
                    this.active--
                }
            }
        },
    }
};
</script>

<style lang="scss">
    .leochApply{
        width: 1120px;
        background: #fff;
        margin:20px auto;
        padding: 40px;
        .el-step.is-center .el-step__line{
            left: 60%;
            right: -40%;
        }
        .el-step__title.is-process,.el-step__title.is-finish{
            color: #333;
            font-weight:500;
        }
        .el-step__head.is-finish{
            border-color: #ccc;
        }
        .el-step__head.is-finish .el-step__icon,.el-step__head.is-process .el-step__icon{
            background: #CC0000;
            color: #fff;
            border-color: #CC0000;
        }
        .btns,.btns1{
            width: 200px;
            height: 48px;
            color: #fff;
            text-align: center;
            line-height: 48px;
            background: #CC0000;
            border-radius: 24px;
            cursor: pointer;
        }
        .btns1{
            background: #999;
        }
    }
</style>