<template>
    <div class="" style="width:500px;margin:auto;">
        <el-form label-width="130px" :model="form" ref="vform" :rules="rules">
            <el-form-item label="姓名：" prop="name">
                <el-input v-model="form.name" placeholder="请输入您的姓名"></el-input>
            </el-form-item>
            <el-form-item label="性别：" prop="sex">
                <el-radio-group v-model="form.sex" text-color="#CC0000">
                    <el-radio :label="1">男</el-radio>
                    <el-radio :label="2">女</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="照片：" prop="photo">
                <Upload :accept="'image/*'" @change="getFront" :imgUrl="form.photo"/>
                <div style="color:#CC0000;">请提供1寸免冠照</div>
            </el-form-item>
            <el-form-item label="民族：" prop="nation">
                <el-input v-model="form.nation" placeholder="请输入您的民族"></el-input>
            </el-form-item>
            <el-form-item label="籍贯：" prop="location">
                <el-input v-model="form.location" placeholder="请输入您的籍贯"></el-input>
            </el-form-item>
            <el-form-item label="政治面貌：" prop="political_outlook">
                <el-input v-model="form.political_outlook" placeholder="请输入您的政治面貌"></el-input>
            </el-form-item>
            <el-form-item label="学历：" prop="education">
                <el-input v-model="form.education" placeholder="请输入您的学历"></el-input>
            </el-form-item>
            <el-form-item label="职称：">
                <el-input v-model="form.professional_call" placeholder="请输入您的职称"></el-input>
            </el-form-item>
            <el-form-item label="艺术特长：">
                <el-input v-model="form.specialty" placeholder="请输入您的艺术特长"></el-input>
            </el-form-item>
            <el-form-item label="固定电话：">
                <el-input v-model="form.fixed_tel" placeholder="请输入您的固定电话"></el-input>
            </el-form-item>
            <el-form-item label="联系电话：" prop="phone">
                <el-input v-model="form.phone" placeholder="请输入您的联系电话"></el-input>
            </el-form-item>
            <el-form-item label="QQ：">
                <el-input v-model="form.qq" placeholder="请输入您的QQ"></el-input>
            </el-form-item>
            <el-form-item label="微信账号：">
                <el-input v-model="form.wechat" placeholder="请输入您的微信账号"></el-input>
            </el-form-item>
            <el-form-item label="微博账号：">
                <el-input v-model="form.weibo" placeholder="请输入您的微博账号"></el-input>
            </el-form-item>
            <el-form-item label="推荐人（理事）：">
                <el-input v-model="form.from_user_no" placeholder="请输入您的推荐人（理事）"></el-input>
            </el-form-item>
            <el-form-item label="工作单位：">
                <el-input v-model="form.company" placeholder="请输入您的工作单位"></el-input>
            </el-form-item>
            <el-form-item label="职务：">
                <el-input v-model="form.job" placeholder="请输入您的职务"></el-input>
            </el-form-item>
            <el-form-item label="通讯地址：" prop="district_id">
                <div class="fx">
                    <el-select v-model="form.province_id" placeholder="请选择省份" @change="form.city_id='',form.district_id='',getcity(form.province_id)">
                        <el-option v-for="item in province" :key="item.id" :label="item.name" :value="item.id"></el-option>
                    </el-select>
                    <el-select v-model="form.city_id" placeholder="请选择城市" @change="form.district_id='',getdistrict(form.city_id)">
                        <el-option v-for="item in city" :key="item.id" :label="item.name" :value="item.id"></el-option>
                    </el-select>
                    <el-select v-model="form.district_id" placeholder="请选择地区" @change="$forceUpdate()">
                        <el-option v-for="item in district" :key="item.id" :label="item.name" :value="item.id"></el-option>
                    </el-select>
                </div>
            </el-form-item>
            <el-form-item label="详细地址：" prop="address">
                <el-input v-model="form.address" placeholder="请输入您的详细地址"></el-input>
            </el-form-item>
            <el-form-item label="电子邮箱：">
                <el-input v-model="form.email" placeholder="请输入您的电子邮箱"></el-input>
            </el-form-item>
            <el-form-item label="社会职务：">
                <el-input v-model="form.social_jobs" placeholder="请输入您的社会职务"></el-input>
                <div>（包括人大代表、政协委员及省市县级社会组织）</div>
            </el-form-item>
        </el-form>
        <div class="t-center fx_around" style="width:500px;margin:auto">
            <div class="btns" @click="next_step">下一步</div>
        </div>
    </div>
</template>

<script>
import {areas} from '@/api/commit'
export default {
    data() {
        return {
            form:{
                photo:''
            },
            rules:{
                name:{ required: true, message: "请输入您的姓名", trigger: "blur" },
                sex:{ required: true, message: "请选择性别", trigger: "change" },
                photo:{ required: true, message: "请上传您的照片", trigger: "blur" },
                nation:{ required: true, message: "请输入您的民族", trigger: "blur" },
                location:{ required: true, message: "请输入您的籍贯", trigger: "blur" },
                political_outlook:{ required: true, message: "请输入您的政治面貌", trigger: "blur" },
                education:{ required: true, message: "请输入您的学历", trigger: "blur" },
                phone:{ required: true, message: "请输入您的联系电话", trigger: "blur" },
                district_id:{ required: true, message: "请选择您的通讯地址", trigger: "blur" },
                address:{ required: true, message: "请输入您的详细地址", trigger: "blur" }
            },
            province:[],
            city:[],
            district:[],
        };
    },
    props:{
        forms:{
            default:null,
            type:Object
        }
    },
    watch:{
        forms:{
            handler:function(val){ 
                if(JSON.stringify(val)!='{}'){
                    this.form = val
                    if(this.form.province_id){
                        this.getcity(this.form.province_id)
                    }
                    if(this.form.city_id){
                        this.getdistrict(this.form.city_id)
                    }
                    return
                }
            },  
            immediate:true,//关键
            deep:true
        },
    },
    created() {
        this.getprovince()
        if(localStorage.getItem('oneFrom2')){
            this.form = JSON.parse(localStorage.getItem('oneFrom2'))
            if(this.form.province_id){
                this.getcity(this.form.province_id)
            }
            if(this.form.city_id){
                this.getdistrict(this.form.city_id)
            }
        }
    },
    mounted() {

    },
    methods: {
        getprovince(){
            areas(0).then(res=>{
                if(res.code==200){
                    this.province = res.data
                }
            })
        },
        getcity(e){
            areas(e).then(res=>{
                if(res.code==200){
                    this.city = res.data
                }
            })
        },
        getdistrict(e){
            areas(e).then(res=>{
                if(res.code==200){
                    this.district = res.data
                }
            })
        },
        Previous_step(){
            this.$emit('change',0)
        },
        next_step(){
            this.$refs["vform"].validate((valid) => {
                if (valid) {
                    localStorage.setItem('oneFrom2',JSON.stringify(this.form))
                    this.$emit('change',1)
                }
            });
        },
        getFront(res){
            this.form.photo = res
        },
    }
};
</script>

<style scoped lang="scss">
    ::v-deep .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    ::v-deep .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }
    ::v-deep .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 100px;
        height: 140px;
        line-height: 140px;
        text-align: center;
        border-radius: 50%;
    }
    ::v-deep .avatar {
        width: 100px;
        height: 140px;
        display: block;
    }
</style>
