<template>
    <div>
        <div v-if="status==0">
            <div class="t-center" style="color:#CC0000;margin:40px 0px;">
                申请信息提交后将不可更改<br/>您确定要提交申请？
            </div>
            <div class="t-center fx_around" style="width:500px;margin:auto">
                <div class="btns1" @click="Previous_step">上一步</div>
                <div class="btns" @click="submit">提交申请</div>
            </div>
        </div>
        <div class="t-center" style="margin:40px 0px;" v-if="status==1">
            <div>会员申请提交成功</div>
            <div style="color:#CC0000;">您需支付会费{{orderId.amount}}元（￥{{orderId.price}}/年，共计{{orderId.years}}年）</div>
            <div class="codeimg">
                <div class="qrcode" ref="qrCodeUrl"></div>
                <!-- <img :src="wechatCode.result.code_url" alt=""> -->
            </div>
            <img src="shexie/img/Scan_code.png" alt="">
        </div>
        <div class="t-center" v-if="status==2">
            <img src="shexie/img/success.png" alt="">
            <div style="margin:20px 0px;">
                <div>您已成功支付会费，工作人员会在规定时间内审核您的申请<br/>您可在“个人中心”-“会员专区”查看审核进度</div>
            </div>
            <div class="btns" style="margin:auto;" @click="$router.push('/member')">确定</div>
        </div>
    </div>
</template>

<script>
import QRCode from 'qrcodejs2'
import {leoch_apply,committee_edit} from '@/api/member'
import {wechat_pay,payment_order} from '@/api/order'
export default {
    data() {
        return {
            orderId:{},
            wechatCode:{},
            status:0,
            inter: null,
            interTime: 60,
            isSuccess: false,
        };
    },
    props:{
        id:{
            default:null,
            type:String | Number
        }
    },
    created() {

    },
    destroyed() {
        this.destoryInter()
    },
    mounted() {

    },
    methods: {
        Previous_step(){
            this.$emit('change',0)
        },
        submit(){
            if(localStorage.getItem('twoFrom2')){
                let two = JSON.parse(localStorage.getItem('twoFrom2'))
                let one = JSON.parse(localStorage.getItem('oneFrom2'))
                this.form = Object.assign(one,two)
            }
            let Interface
            if(this.id){
                Interface = committee_edit
            }else{
                Interface = leoch_apply
            }
            Interface(this.form,this.id).then(res=>{
                if(res.code==200){
                    if(this.id){
                        this.$router.push('/member')
                    }else{
                        this.status = 1
                        this.orderId = res.data
                        this.getWechat()
                    }
                    localStorage.removeItem('oneFrom2')
                    localStorage.removeItem('twoFrom2')
                }
            })
        },
        getWechat(){
            wechat_pay(this.orderId.order_id).then(res=>{
                if(res.code==200){
                    var qrcode = new QRCode(this.$refs.qrCodeUrl, {
                        text: res.data.result.code_url, // 需要转换为二维码的内容
                        width: 200,
                        height: 200,
                        colorDark: '#000000',
                        colorLight: '#ffffff',
                        correctLevel: QRCode.CorrectLevel.H
                    })
                    this.wechatCode = res.data
                    this.startInter()
                }
            })
        },
        //判断有没有支付完成
        startInter() {
            this.destoryInter();
            this.inter = setInterval(() => {
                --this.interTime;
                if (this.interTime % 2 == 0) {
                    this.orderCheck();
                }
                if (this.interTime < 1) {
                    this.destoryInter();
                    //支付时间已过;
                }
            }, 1000);
        },
        destoryInter() {
            if (this.inter) {
                clearInterval(this.inter);
                this.inter = null;
                this.interTime = 60;
                this.$router.push('/member')
            }
        },
        //是否成功
        orderCheck() {
            payment_order({order_sn:this.wechatCode.order_sn}).then(res=>{
                if(res.code==200){
                    this.$message.success("支付成功!");
                    this.isSuccess = true;
                    this.status = 2
                    clearInterval(this.inter);
                }
            })
        },
    }
};
</script>

<style scoped lang="scss">
    .codeimg{
        margin: 20px 0px;
        img{
            padding: 10px;
            border: 1px solid #ccc;
            width:200px;
            height: 200px;
        }
        .qrcode{
            padding: 10px;
            border: 1px solid #ccc;
            width:200px;
            height: 200px;
            margin: auto;
        }
    }
</style>
